import { queryOptions } from "@tanstack/vue-query";
import { Statistics } from "~/src/api";
import { useCacheTimings } from "~/composables/cacheTiming";

export const useTitleStats = (
  titleId: MaybeRefOrGetter<string | null | undefined>,
) =>
  queryOptions({
    queryKey: ["composite_stats", "entity", "title", titleId],
    queryFn: async () => {
      const titleIdValue = toValue(titleId);
      if (!titleIdValue) return null;
      return await Statistics.getTitleStats(titleIdValue);
    },
    staleTime: useCacheTimings().entity.composite_stats,
  });

export const useChapterStats = (
  chapterId: MaybeRefOrGetter<string | null | undefined>,
) =>
  queryOptions({
    queryKey: ["composite_stats", "entity", "chapter", chapterId],
    queryFn: async () => {
      const chapterIdValue = toValue(chapterId);
      if (!chapterIdValue) return null;
      if (chapterIdValue === "unknown") return null;
      return await Statistics.getChapterStats(chapterIdValue);
    },
    staleTime: useCacheTimings().entity.composite_stats,
  });
